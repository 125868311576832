<template>
  <div>
    <div>
      <div class="sm:mx-auto sm:w-full sm:max-w-md py-24 ">
        <img src="../assets/img/logo_full.png" class="mx-auto h-24 w-auto" alt="Logo"/>
      </div>
    </div>
    <div v-if="!isLoading">
      <div class='flex flex-col items-center' v-if="!showOptions">
        <button class='btn' @click="showOptions = true">Connect your wallet</button>
      </div>
      <Transition name="fade">
        <div class="bg-white shadow overflow-hidden sm:rounded-md transition-all fade" v-if="showOptions">
          <ul role="list" class="divide-y divide-gray-200">
            <li>
              <div class="block hover:bg-gray-50 cursor-pointer" @click="connectMetaMask">
                <div class="px-4 py-4 flex items-center sm:px-6">
                  <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <p class="font-medium text-indigo-600 truncate">MetaMask</p>
                  </div>
                  <div class="ml-5 flex-shrink-0">
                    <img src="../assets/svg/metamask.svg" class="w-8 h-8" alt="MetaMask">
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="block hover:bg-gray-50 cursor-pointer" @click="connectCoinBase">
                <div class="px-4 py-4 flex items-center sm:px-6">
                  <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <p class="font-medium text-indigo-600 truncate">CoinBase</p>
                  </div>
                  <div class="ml-5 flex-shrink-0">
                    <img src="../assets/svg/coinbase.svg" class="w-8 h-8" alt="CoinBase">
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="block hover:bg-gray-50 cursor-pointer" @click="connectWalletConnect">
                <div class="px-4 py-4 flex items-center sm:px-6">
                  <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <p class="font-medium text-indigo-600 truncate">WalletConnect</p>
                  </div>
                  <div class="ml-5 flex-shrink-0">
                    <img src="../assets/svg/wallet_connect.svg" class="w-8 h-8" alt="WalletConnect">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
    <AppLoader v-if="isLoading">Loading connector</AppLoader>
  </div>
</template>
<script>
import { inject, onMounted, ref, Transition } from "vue";
import connection from '../utils/crypto/connection'
import AppLoader from "./AppLoader";


export default {
  components: {
    AppLoader, Transition
  },
  props: {},
  setup()
  {

    const showOptions = ref(false)
    const isLoading = ref(true)
    const state = inject('state')


    async function isConnected()
    {
      //Is connected?
      let connectedAccount = await connection.connected();
      if (connectedAccount) {
        state.value = 'portal'
      }
    }

    async function connectMetaMask()
    {
      let connected = await connection.connectMetaMask()
      if (connected) {
        state.value = 'portal'
      }
      await isConnected();
    }


    async function connectCoinBase()
    {
      let connected = await connection.connectCoinBase(window.$defaultNetwork);
      if (connected) {
        state.value = 'portal'
      }
      await isConnected();
    }

    async function connectWalletConnect()
    {
      let connected = await connection.connectWalletConnect(window.$defaultNetwork)
      if (connected) {
        state.value = 'portal'
      }
      await isConnected();
    }

    onMounted(async () => {

      await isConnected();
      isLoading.value = false
    })


    return { connectMetaMask, connectCoinBase, connectWalletConnect, showOptions, isLoading }
  }

}
</script>